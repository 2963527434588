<template>
  <div class="company-settings" v-loading="loading">

    <div
      v-if="$canAndRulesPass(user, $permissions.SETTING.UPDATE)"
    >
      <import-modal
        entity="users"
        close-on-success
        @import-succeed="importSucceed"
      >
        <template v-slot:reference>
          <el-button>
            Импорт пользователей
          </el-button>
        </template>
      </import-modal>
    </div>


  </div>
</template>

<script>

import {companySettings} from "@/mixins/companySettings";
import {downloader} from "@/mixins/downloader";
import ImportModal from "@/components/exportImport/ImportModal.vue";
import {mapGetters} from "vuex";

export default {
  name: "company-settings",
  components: {ImportModal},
  mixins: [companySettings],

  props: {},
  computed: {
    ...mapGetters(['user']),
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    importSucceed(){
      this.$notify.success({
        title: 'Завершено',
        message: 'Импорт завершен успешно'
      });
    },
  }
}
</script>


<style lang="scss">

</style>